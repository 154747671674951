import { ready } from "./utils.js";

ready(() => {
  const header = document.getElementById("fadInNav");

  function updateHeaderScrollClasses() {
    const scrollY = window.scrollY;
    if (scrollY > 250) {
      header.classList.add("small");
    } else if (scrollY < 150) {
      header.classList.remove("small");
    }
  }
  
  if (header) {
    document.addEventListener("scroll", updateHeaderScrollClasses); //on scroll
    //updateHeaderScrollClasses(); //after reload
  }
});

